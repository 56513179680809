
import * as logger from './grape.logger';
import * as utils from './grape.utils';

import AutoBind from './grape.autobind';
import GrapeCache from './grape.cache';
import DefaultConfig from './grape.config';
import GrapeDataModels from './grape.dataModel';
import GrapeComponents from './grape.component';
import GrapeDialogs from './grape.dialog';
import GrapeTables from './grape.tables';
import GrapePages from './grape.pages';
import GrapePlugins from './grape.plugins';
import GrapeFetches from './grape.fetches';
import GrapeRouter from './router/GrapeRouter.js';
import Registry from './Registry.js';

import EventEmitter from 'eventemitter3';

const GRAPE_UI_EVENTS = {

};

/**
 * @class GrapeApp
 */
class GrapeApp extends EventEmitter {
	constructor() {
		super();

		grape_ui_2: {
			this.__registry = new Registry(this);
			this.__cache = new GrapeCache(this);
			this.__dataModel = new GrapeDataModels(this);
			this.__component = new GrapeComponents(this);
			this.__dialog = new GrapeDialogs(this);
			this.__logger = logger;
			this.__tables = new GrapeTables(this);
			this.__plugins = new GrapePlugins(this);
			this.__fetches = new GrapeFetches(this);
			this.__utils = utils;

			this.__router = new GrapeRouter({
				beforeEach: async (to, from) => { console.info('[ROUTER] BEFORE EACH: ', to.uri); },
				afterEach: async (to, from) => { console.info('[ROUTER] AFTER EACH: ', from.uri); }
			});

			this.config = DefaultConfig;

			this.autobind = AutoBind;

			this.pages = new GrapePages(this);
			this.modules = [];
		}

		deprecated: {
			this.models = {};
			this.frames = {
				'header': null,
				'footer': null,
				'left': null,
				'right': null,
				'main': null
			};
			this.vm = {}; // viewmodels
			this.locals = {};

			this.currentPage = null;
			this.currentPages = {};
			this.currentSession = null;
			this.configLoaded = false;
			this.sessionLoaded = false;
			this.invalidSessionHandlerInstalled = false;

			this.shortcut_registry = [];
			this.keypress_stack = '';
			this.dynamic_navbar_items = {};
		}

		this.pending_init_promises = [];

		this.dialogs = this.dialog.dialogs;
	}

	log(...p) { console.log(...p); }
	navigate(...p) { Grape.router.navigate(...p); }
	show_dialog(...p) { return this.dialog.show(...p); }

	/**
	 * @memberof GrapeApp
	 * @type {GrapeCache}
	 */
	get cache() { return this.__cache; }

	/**
	 * @memberof GrapeApp
	 * @type {GrapeDataModels}
	 */
	get dataModel() { return this.__dataModel; }

	/**
	 * @memberof GrapeApp
	 * @type {GrapeComponents}
	 */
	get component() { return this.__component; }

	/**
	 * @memberof GrapeApp
	 * @type {GrapeDialogs}
	 */
	get dialog() { return this.__dialog; }

	get registry() { return this.__registry; }

	get logger() { return this.__logger; }
	get navbars() { return this.__navbars; }
	get tables() { return this.__tables; }
	get utils() { return this.__utils; }
	get plugins() { return this.__plugins; }
	get fetches() { return this.__fetches; }
	get router() { return this.__router; }
}

window.GrapeApp = GrapeApp;

GrapeApp.prototype.update_visible_roles = (_roles = null) => {
	let roles;
	if (!_roles)
	{
		if (window.Grape.currentSession && window.Grape.currentSession.roles)
			roles = window.Grape.currentSession.roles;
		else
			roles = ['guest'];
	}
	else
	{
		if (typeof _roles === 'string')
			roles = _roles.split(/[\s\,]/);
		else if (Array.isArray(_roles))
			roles = _roles;
		else
			roles = []; // invalid input for function
	}

	// EVENT: Hide all elements
	for (let element of document.querySelectorAll('[data-visible-roles]'))
		element.style.display = 'none';

	// remove guest from role list
	let idx = roles.indexOf('guest');
	if (idx > -1)
		roles.splice(idx, 1);

	if (!roles || roles.length == 0)
		roles = ['guest'];
	else if (roles.indexOf('all') < 0)
		roles.push('all');

	console.debug('Update visible elements with user roles (', roles.join(', '), ')');

	// EVENT: Display HTML elements for User Roles
	for (let role of roles)
		for (let element of document.querySelectorAll(`[data-visible-roles~="${role}"]`))
			element.style.display = '';
};

export default GrapeApp;
