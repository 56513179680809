/**
 * @class GrapeDataModels
 * @description An instance of this class is usually available as window.Grape.dataModel
 */
export default class GrapeDataModels {
	constructor(Grape) {
		this.Grape = Grape;
		this.dataModels = {};
	}

	registerDataModels(dataModels){
		for (let dataModel of dataModels){
			this.register(dataModel);
		}
	}

	register(dataModel){
		if (!dataModel?.name)
			throw new Error('Cannot register Data Model without specifying "name"');
		if (!dataModel?.dataModel)
			throw new Error(`Cannot register Data Model "${dataModel?.name}" without specifying "dataModel"`);
		if (this.dataModels[dataModel.name])
			throw new Error(`Cannot override Data Model "${dataModel?.name}"`);
		this.dataModels[dataModel.name] = dataModel;
	}

	get(dataModelName){
		return this.dataModels?.[dataModelName]?.dataModel;
	}
}
